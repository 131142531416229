<template>
  <div class="row">
    <div class="col-md-7">
      <div class="row">
        <div class="col-md-4">
          <div class="field form-group">
            <label>Уровень</label>
            <div class="field-value">{{group.exam.level.name}}</div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="field form-group">
            <label>Город</label>
            <div class="field-value">{{group.exam.city.name}}</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="field form-group">
            <label>Дата экзамена</label>
            <div class="field-value">{{moment(group.exam.examDate).format('DD MMMM YYYY')}}</div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="field form-group">
            <label>Время начала</label>
            <div class="field-value">{{group.exam.examTimeStart ? group.exam.examTimeStart : 'не назначено'}}</div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="field form-group">
            <label>Время окончания</label>
            <div class="field-value">{{group.exam.examTimeEnd ? group.exam.examTimeEnd : 'не назначено'}}</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="field form-group">
            <label>Помещение</label>
            <div class="field-value">{{group.exam.location ? group.exam.location.place : 'не назначено'}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-5">
      <div class="field form-group">
        <label>Модули</label>
        <div class="field-value">
          <div v-for="module in group.modules">
            {{module.name}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "GroupHeader",
    props: ['group']
  }
</script>
