<template>
  <div class="login-layout" v-show="!authUser.isAuth()">
    <main>
      <slot />
    </main>
  </div>
</template>

<script>
  import User from '@/components/User';

  export default {
    components: {User},
    data() {
      return {
        authUser: User
      }
    }
  }
</script>