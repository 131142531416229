<template>
  <div class="rules-layout" v-show="authUser.isAuth()">
    <header class="header">
      <nav class="navbar-default">
        <div class="container">
          <div class="navbar-block text-center">
            <UserInfo></UserInfo>
          </div>
        </div>
      </nav>
    </header>
    <main class="container">
      <slot />
    </main>
  </div>
</template>
<script>

import UserInfo from "@/components/UserInfo";
import User from "@/components/User";

export default {
  components: {UserInfo, User},
  data() {
    return {
      authUser: User
    }
  },
  created() {
    if (!User.isAuth()) {
      this.$router.push({name: 'Login'})
    }
  }
}
</script>