<template>
  <div class="sequence-test form-group">
    <div class="d-flex pt-3">
      <div class="positions">
        <div class="form-group" v-for="(answer, index) in segment.test.answers">
          <span class="sortable-position">{{index + 1}}</span>
          <span v-if="preview">
            &nbsp;
            <span class="sortable-position correct-position">{{answer.correctPosition}}</span>
          </span>
        </div>
      </div>
      <Draggable class="answers" v-model="applicantAnswer">
        <div class="form-group cursor-pointer ml-3" v-for="answer in applicantAnswer" :key="answer.id">
          <span v-html="answer.content"></span>
        </div>
      </Draggable>
    </div>
  </div>
</template>

<script>
  import Draggable from 'vuedraggable'

  export default {
    name: "SequenceTest",
    props: ['segment', 'changeCallback', 'preview'],
    components: {Draggable},
    data() {
      return {
        applicantAnswer: []
      }
    },
    created() {
      this.applicantAnswer = this.segment.test.answers
    },
    watch: {
      applicantAnswer: function (value) {
        this.$store.commit('addAnswer', {
          segmentId: this.segment.id,
          answers: value.map(item => item.id)
        })

        // вызываем тригер изменения
        if (this.changeCallback) {
          this.changeCallback(value)
        }
      }
    }
  }
</script>

<style>
  .answers .sortable-chosen {
    background-color: #f5f5f5;
    padding: 5px;
  }

  .answers .sortable-ghost {
    border: 2px dashed #aaa;
    padding: 5px;
  }
</style>
