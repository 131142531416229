<template>
  <div class="supervisor-layout" v-show="authUser.isAuth()">
    <header class="header">
      <nav class="navbar-default navbar-supervisor">
        <b-navbar type="dark" toggleable="md" class="container">
          <b-navbar-brand href="#">{{authUser.getFullName()}}</b-navbar-brand>
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
              <li class="nav-item">
                <router-link class="nav-link" :to="{name: 'CreateExam'}">Добавить экзамен</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{name: 'Groups'}">Список групп</router-link>
              </li>
              <b-nav-item @click="logout" title="Выйти">
                <svg class="icon-logout" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                  <path d="M13 3h-2v10h2V3zm4.83 2.17l-1.42 1.42C17.99 7.86 19 9.81 19 12c0 3.87-3.13 7-7 7s-7-3.13-7-7c0-2.19 1.01-4.14 2.58-5.42L6.17 5.17C4.23 6.82 3 9.26 3 12c0 4.97 4.03 9 9 9s9-4.03 9-9c0-2.74-1.23-5.18-3.17-6.83z"></path>
                </svg>
              </b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </nav>
    </header>
    <main class="container">
      <slot />
    </main>
  </div>
</template>
<script>

import UserInfo from "@/components/UserInfo";
import User from "@/components/User";

export default {
  components: {UserInfo, User},
  data() {
    return {
      authUser: User
    }
  },
  created() {
    if (!User.isAuth()) {
      this.$router.push({name: 'Login'})
    }
  },
  methods: {
    logout() {
      this.authUser.logout()
      location.href = '/'
    }
  }
}
</script>