import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ticket: {},
    answers: [],
    remains: 40
  },
  getters: {
    getTicket: state => {
      return state.ticket
    },
    getAnswers: state => {
      return state.answers
    },
    getRemain: state => {
      return state.remains
    },

    /**
     * Возвращает ответ по ID сегмента
     * @param state
     * @return function(*): ([]|[any]|*|null)
     */
    getAnswersById: state => segmentId => {
      for (let i = 0; i < state.answers.length; i++) {
        if (state.answers[i].segmentId === segmentId) {
          return state.answers[i].answers
        }
      }
      return null
    }
  },
  mutations: {
    setTicket: (state, data) => {
      state.ticket = data
    },
    setAnswers: (state, data) => {
      state.answers = data
    },
    setRemain: (state, data) => {
      state.remains = data
    },

    /**
     * Добавляет ответ.
     * @param state
     * @param data
     */
    addAnswer: (state, data) => {
      for (let i = 0; i < state.answers.length; i++) {
        if (state.answers[i].segmentId === data.segmentId) {
          state.answers[i] = data
          return
        }
      }
      state.answers.push(data)
    }
  }
})
