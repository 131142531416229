<template>
  <div class="compound-test form-group">
    <ul class="form-group list-unstyled" v-for="question in segment.test.questions">
      <li class="form-group mb-4">
        <div class="mb-1" v-html="question.content"></div>
        <b-form-group>
          <ol>
            <li v-for="answer in question.answers" class="mt-2">
              <b-form-radio :name="'question-' + question.id" v-model="applicantAnswer[question.id]" :value="answer.id">
                <span :class="(answer.correct && preview) ? 'text-correct' : ''" v-html="answer.content"></span>
              </b-form-radio>
            </li>
          </ol>
        </b-form-group>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: "CompoundTest",
    props: ['segment', 'changeCallback', 'preview'],
    data() {
      return {
        applicantAnswer: []
      }
    },
    watch: {
      applicantAnswer: function (value) {
        let answers = []
        value.forEach((answer, questionId) => {
          answers.push({
            questionId: questionId,
            answerId: answer
          })
        })

        this.$store.commit('addAnswer', {
          segmentId: this.segment.id,
          answers: answers
        })

        // вызываем тригер изменения
        if (this.changeCallback) {
          this.changeCallback(value)
        }
      }
    }
  }
</script>
