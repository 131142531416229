<template>
  <section class="create-exam">
    <h1 class="mb-3">Создать DEMO экзамен</h1>

    <div class="panel form-group mt-3">
      <form @submit="create">
        <div class="form-group">
          <div>
            <label>Модуль</label>
            <select class="form-control" v-model="moduleId">
              <option></option>
              <option v-for="item in modules" :value="item.id">{{item.name}}</option>
            </select>
          </div>
          <div class="error">{{errors.moduleId}}</div>
        </div>
        <div class="form-group">
          <label>Регистрационный номер</label>
          <input type="text" class="form-control" v-model="regNumber">
          <div class="error">{{errors.login}}</div>
        </div>
        <div>
          <button type="submit" class="btn btn-danger" :disabled="sending">Создать</button>
        </div>
      </form>
    </div>

    <b-modal id="modal-create-exam" hide-footer>
      <h4 class="form-group">Экзамен создан</h4>
      <p>Мы сгенрировали данные и отправили их претенденту на почту.</p>
    </b-modal>

    <div class="alert-block">
      <div class="alert alert-danger" v-show="alert.length > 0">{{alert}}</div>
    </div>
  </section>
</template>

<script>

export default {
  name: "CreateExam",
  data() {
    return {
      modules: [],
      moduleId: null,
      regNumber: '',
      errors: {},
      alert: '',
      success: false,
      sending: false
    }
  },
  methods: {
    create(e) {
      if (this.validate()) {
        this.sending = true
        this.$http.post('/demo/create', {
          regNumber: this.regNumber.trim(),
          moduleId: this.moduleId
        })
            .then((response) => {
              this.sending = false
              this.success = true
              this.regNumber = ''
              this.moduleId = null
              this.$bvModal.show('modal-create-exam')
            })
            .catch((error) => {
              this.sending = false
              this.alert = error.response.data[0].message
              setTimeout(() => this.alert = '', 3000)
            })
      }
      e.preventDefault()
    },

    /**
     * Валидация формы.
     * @return {boolean}
     */
    validate() {
      this.errors = {}

      if (!this.moduleId) {
        this.errors.moduleId = 'Выберите модуль.'
      }

      if (this.regNumber.trim().length === 0) {
        this.errors.login = 'Заполните Регистрационный номер.'
      }

      return Object.keys(this.errors).length === 0
    },

    /**
     * Загружает модули.
     */
    loadModules() {
      this.$http.get('/modules')
          .then((response) => {this.modules = response.data})
    }
  },
  created() {
    this.loadModules()
  }
}
</script>
