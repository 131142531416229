<template>
  <div class="home">
    <div class="panel form-group mt-3" v-show="isFinishedExam">
      <h5>Экзамен закончен!</h5>
    </div>
    <div class="panel form-group mt-3" v-show="finishIndicator">
      <h5>Завершаем экзамен ...</h5>
    </div>
    <div class="row form-group" v-show="segmentsCount > 0">
      <div class="col-md-6">
        Осталось вопросов {{notAnsweredCount}} из {{segmentsCount}}
      </div>
      <div class="col-md-6 text-right">
        <button class="btn btn-default text-uppercase" v-b-modal.modal-help>
          <b-icon icon="info-circle-fill" class="icon" style="fill: #555"></b-icon>
          &nbsp; &nbsp; &nbsp;
          помощь
        </button>
      </div>
    </div>
    <b-tabs content-class="mt-3" class="tabs-segments" v-model="currentIndex" v-show="showTabs">
      <b-tab
          lazy
          v-bind:title="'' + (index + 1)"
          :title-item-class="segment.isAnswered ? 'answered' : ''"
          v-for="(segment, index) in ticket.segments"
          :disabled="segment.isAnswered == 1">
        <div class="form-group">
          <div class="panel form-group">
            <section class="segment">
              <h5 class="form-group font-weight-bold">
                {{segment.test.module.name}}
              </h5>
              <div class="form-group text">
                <strong v-html="segment.test.question.content"></strong>
              </div>
              <div v-if="segment.test.typeId === 1" class="segment-answers">
                <SimpleTest :segment="segment"></SimpleTest>
              </div>
              <div v-if="segment.test.typeId === 2" class="segment-answers">
                <MultipleTest :segment="segment"></MultipleTest>
              </div>
              <div v-if="segment.test.typeId === 3" class="segment-answers">
                <MatchingTest :segment="segment"></MatchingTest>
              </div>
              <div v-if="segment.test.typeId === 4" class="segment-answers">
                <CompoundTest :segment="segment"></CompoundTest>
              </div>
              <div v-if="segment.test.typeId === 5" class="segment-answers">
                <SequenceTest :segment="segment"></SequenceTest>
              </div>
              <div v-if="segment.test.typeId === 6" class="segment-answers">
                <CalculateTest :segment="segment"></CalculateTest>
              </div>
            </section>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-md-6">
                <button class="btn btn-bordered text-uppercase" v-show="notAnsweredCount > 1" @click="onSkip">ответить позже</button>
              </div>
              <div class="col-md-6 text-right">
                <button class="btn btn-danger text-uppercase" @click="onSaveAnswer">ответить</button>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>

    <div class="alert-block">
      <div class="alert alert-danger" v-show="alert.length > 0">{{alert}}</div>
    </div>

    <b-modal id="modal-help" scrollable centered size="lg" :hide-footer="true">
      <p>Для перехода к следующему вопросу без ответа на открытый вопрос нажмите кнопку «Ответить позже».</p>
      <p>Для выбора вопроса, на который не дан ответ, нажмите соответствующий номер в строке с последовательностью вопросов.</p>
      <p>Открыть вопрос, ответ на который уже дан, нельзя.</p>
      <p>В тесте с вариативным количеством ответов выберите то количество вариантов ответов, которое указано в задании (ДВА, ТРИ и т.д.). При выборе большего или меньшего количества вариантов, ответ на вопрос засчитан не будет.</p>
      <p>При ответе на вопрос-последовательность, чтобы расположить варианты ответа в необходимом порядке, наведите курсор на строку с вариантом ответа, захватите строку, нажав левую кнопку мыши, и, не отпуская кнопку, перенесите строку на выбранное место.</p>
      <p>Для проверки количества ответов, принятых программой, а также с целью «обновления» страницы используйте кнопку F5.</p>
      <p>При длительной загрузке ответа и иных технических сложностях в работе программы обратитесь к представителю ЕАК.</p>
      <p>По окончании экзамена убедитесь, что в окне с уведомлением о завершении экзамена указано верное количество данных ответов («Отвечено 40 вопросов из 40»).</p>
    </b-modal>

    <b-modal id="modal-finish-exam" hide-footer scrollable size="lg" centered>
      <h4 class="form-group">Экзамен закончен!</h4>
      <div class="row form-group">
        <div class="col-sm-7">
          <div class="finish-group">
            <div class="hint">ФИО претиндента</div>
            <div class="value">{{username}}</div>
          </div>
        </div>
        <div class="col-sm-5">
          <div class="finish-group">
            <div class="hint">Вопросов отвечено</div>
            <div class="value">{{result.stat ? result.stat.answered : ''}} из {{result.stat ? result.stat.total : ''}}</div>
          </div>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-sm-5">
          <div class="finish-group">
            <div class="hint">Уровень</div>
            <div class="value">{{result.level ? result.level.name : ''}}</div>
          </div>
        </div>
        <div class="col-sm-7">
          <div class="finish-group">
            <div class="hint">Дата экзамена</div>
            <div class="value">{{moment().format('DD MMMM YYYY')}}</div>
          </div>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-sm-12">
          <div class="finish-group">
            <div class="hint">Модуль</div>
            <div class="value">{{result.modules ? result.modules[0].name : ''}}</div>
          </div>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-sm-12">
          <div class="finish-group">
            <div class="hint">Оценка</div>
            <div class="value">{{result.mark}}</div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>

import Socket from '@/components/socket'
import User from "@/components/User";
import SimpleTest from "@/components/tests/SimpleTest";
import CompoundTest from "@/components/tests/CompoundTest";
import MultipleTest from "@/components/tests/MultipleTest";
import MatchingTest from "@/components/tests/MatchingTest";
import SequenceTest from "@/components/tests/SequenceTest";
import CalculateTest from "@/components/tests/CalculateTest";

export default {
  name: 'Exam',
  components: {CalculateTest, SequenceTest, MatchingTest, MultipleTest, CompoundTest, SimpleTest},
  data() {
    return {
      authUser: User,
      currentIndex: 0,
      ticket: {},
      segmentsCount: 0,
      notAnsweredCount: 0,
      answers: [],
      alert: '',
      isFinishedExam: false,
      finishIndicator: false,
      result: {},
      username: User.getFullName(),
      showTabs: true
    }
  },
  created() {
    Socket.subscribe('private', 'ticket', (response) => {
      // проверяем, закончен ли экзамен
      if (response.status === 400) {
        this.authUser.logout()
        location.reload()
        return
      }

      // сохраняем данные билета
      this.saveTicket(response.data)
    })

    Socket.subscribeTimer((response) => {
      this.showTabs = response.status !== 'pause'
      if (response.time > 0 && response.time <= 600) {
        document.querySelector('.time-expired').classList.add('time-danger')
      } else {
        document.querySelector('.time-expired').classList.remove('time-danger')
      }
    })

    Socket.subscribe('private', 'extend_time', (response) => {
      this.alert = response.message
      setTimeout(() => this.alert = '', 5000)
    })

    Socket.subscribe('private', 'fail', (response) => {
      this.alert = 'Нарушение сдачи экзамена.'
      setTimeout(() => {
        this.alert = ''
        User.logout()
        location.href = '/'
      }, 3000)
    })
  },

  methods: {
    /**
     * Пропускает сегмент, но при этом сохраняет текущий резальтат.
     */
    onSkip() {
      this.moveToNext()
    },

    /**
     * Сохраняет ответы.
     */
    onSaveAnswer(event) {
      const segmentId = this.ticket.segments[this.currentIndex].id
      const params = {
        segmentId: segmentId,
        answers: this.$store.getters.getAnswersById(segmentId)
      }

      event.target.setAttribute('disabled', true);
      this.$http.post('/answer/test', params)
        .then((response) => {
          this.ticket.segments[this.currentIndex].isAnswered = 1
          this.notAnsweredCount = response.data.remains

          // сохраняем оставшиеся кол-во тестов в стор
          this.$store.commit('setRemain', this.notAnsweredCount)

          // если больше не осталось вопросов, то автоматом завершаем экзамен
          if (this.notAnsweredCount === 0) {
            this.finishExam()
          }

          event.target.removeAttribute('disabled');
          this.moveToNext()
        })
        .catch((error) => {
          event.target.removeAttribute('disabled');
          this.alert = 'Возникли ошибки при сохранении ответа.'
          setTimeout(() => this.alert = '', 3000)
        })
    },

    finishExam() {
      this.finishIndicator = true
      this.$http.post('/demo/test-finish')
          .then((response) => {
            this.finishIndicator = false
            Socket.send('member_finish_exam')
            this.result = response.data
            this.authUser.logout()
            this.$bvModal.show('modal-finish-exam')
            setTimeout(() => {
              this.$nextTick(() => {
                this.$bvModal.hide('modal-finish-exam')
              })
              location.reload()
            }, 20000)
          })
          .catch((error) => {
            this.finishIndicator = false
            this.alert = 'Возникли проблемы с завершением экзамена.'
            setTimeout(() => this.alert = '', 3000)
          })
    },

    /**
     * Возвращает ответ по ID вопроса
     * @param questionId
     * @return {*}
     */
    getAnswer(questionId) {
      const index = this.answers.map((item) => item.questionId).indexOf(questionId)
      return this.answers[index]
    },

    /**
     * Переключает на следующий сегмент.
     */
    moveToNext() {
      this.enableAnswerButton = false

      for (let i = this.currentIndex; i < this.ticket.segments.length; i++) {
        if (!this.ticket.segments[i].isAnswered && this.currentIndex !== i) {
          this.currentIndex = i;
          return
        }
      }

      for (let i = 0; i < this.currentIndex; i++) {
        if (!this.ticket.segments[i].isAnswered && this.currentIndex !== i) {
          this.currentIndex = i;
          return
        }
      }
    },

    /**
     * Подготавливает и сохраняет данные о билете в стор.
     * @param data
     */
    saveTicket(data) {
      data.segments.forEach((segment) => {
        this.answers.push({
          segmentId: segment.id,
          answers: []
        })
      })

      this.notAnsweredCount = data.remains

      this.ticket = data
      this.segmentsCount = this.ticket.segments.length
      this.$store.commit('setTicket', this.ticket)
      this.$store.commit('setAnswers', this.answers)
      this.$store.commit('setRemain', data.remains)
    }
  }
}

</script>
