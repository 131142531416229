<template>
  <div class="multiple-test form-group">
    <b-form-checkbox-group>
      <div class="form-group" v-for="answer in segment.test.answers">
        <b-form-checkbox v-model="applicantAnswer" name="question" :value="answer.id">
          <span  :class="(answer.correct && preview) ? 'text-correct' : ''" v-html="answer.content"></span>
        </b-form-checkbox>
      </div>
    </b-form-checkbox-group>
  </div>
</template>

<script>
  export default {
    name: "MultipleTest",
    props: ['segment', 'changeCallback', 'preview'],
    data() {
      return {
        applicantAnswer: null
      }
    },
    watch: {
      applicantAnswer: function (value) {
        this.$store.commit('addAnswer', {
          segmentId: this.segment.id,
          answers: value
        })

        // вызываем тригер изменения
        if (this.changeCallback) {
          this.changeCallback(value)
        }
      }
    }
  }
</script>
