<template>
  <section class="grid">
    <section class="panel panel-hide">
      <h4 @click="toggleFilter" class="cursor-pointer">Фильтры</h4>
      <div class="row mt-4">
        <div class="col-md-6">
          <div class="form-group field">
            <label>Название группы</label>
            <div>
              <input type="text" class="field-value" v-model="filterSelected.name">
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group field">
            <label>Уровень экзамена</label>
            <div>
              <select class="field-value" v-model="filterSelected.levelId">
                <option></option>
                <option v-for="level in filter.levels" :value="level.id">{{level.name}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group field">
            <label>Город</label>
            <div>
              <VueSelect :options="filter.cities" label="name" @search="citySearch" v-model="filterSelected.cityId"></VueSelect>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group field">
            <label>Модуль</label>
            <div>
              <VueSelect :options="filter.modules" label="name" v-model="filterSelected.modules" :multiple="true"></VueSelect>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group field">
            <label>Дата экзамена</label>
            <div>
              <input type="date" class="form-control" v-model="filterSelected.examDate">
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group field">
            <label>Помещение</label>
            <div>
              <VueSelect :options="filter.locations" label="place" @search="locationSearch" v-model="filterSelected.location"></VueSelect>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="panel-grid mt-4">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Название</th>
            <th>Модуль</th>
            <th>Дата экзамена</th>
            <th>Город</th>
            <th>Помещение</th>
            <th>Статус</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" @click="onShowGroup(item.id)" class="cursor-pointer" v-if="item.exam">
            <td>{{item.name}}</td>
            <td>
              <div v-for="module in item.modules">
                {{module.name}}
              </div>
            </td>
            <td width="200">{{moment(item.exam.examDate).format('DD MMMM YYYY')}}</td>
            <td>{{item.exam.city.name}}</td>
            <td width="200">{{item.exam.location.place}}</td>
            <td width="200">
              <span class="status-label" :style="'background-color: ' + statuses[item.status].color">
                {{statuses[item.status].text}}
              </span>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="6">
              <div class="row">
                <div class="col-md-6">
                  <Paginate
                      v-model="page"
                      :page-count="pageCount"
                      :page-range="5"
                      :container-class="'pagination'"
                      :page-class="'pagination-item'"
                      :page-link-class="'pagination-item-link'"
                      :prev-text="'<'"
                      :next-text="'>'"
                      :click-handler="loadGroups">
                  </Paginate>
                </div>
                <div class="col-md-6">
                  <img src="../../assets/loader.gif" class="footer-loader" v-show="loading">
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </section>
  </section>
</template>

<script>
  import 'vue-select/dist/vue-select.css'
  import VueSelect from 'vue-select'
  import Paginate from 'vuejs-paginate'
  import Enums from '@/components/enums'

  export default {
    name: "GroupsGrid",
    components: {VueSelect, Paginate},
    data() {
      return {
        loading: false,
        filter: {
          modules: [],
          levels: [],
          locations: [],
          cities: [],
        },
        filterSelected: {
          name: '',
          levelId: null,
          cityId: null,
          modules: [],
          examDate: null,
          location: null
        },
        statuses: Enums.examStatuses,
        page: 1,
        pageCount: 1,
        items: []
      }
    },
    watch: {
      /**
       * Отслеживаем изменения фильтра.
       */
      filterSelected: {
        handler: function (value) {
          this.setQueryParams()
          this.loadGroups()
        },
        deep: true
      },
      page: function () {
        this.setQueryParams()
      }
    },
    created() {
      // загружаем все фильтры
      Promise.all([this.loadModules(), this.loadExamLevels()])
        .then(responses => {
          this.filter.modules = responses[0].data
          this.filter.levels = responses[1].data
          this.loadQueryParams()
          this.loadGroups()
          this.locationSearch()
          this.citySearch(this.filterSelected.cityName)
        })
    },
    methods: {
      /**
       * Загружает группы.
       */
      loadGroups() {
        let filter = {
          name: this.filterSelected.name,
          levelId: this.filterSelected.levelId ? this.filterSelected.levelId : null,
          // modules: this.filterSelected.modules.map(item => item.id),
          examDate: this.filterSelected.examDate ? this.filterSelected.examDate : null,
          // locationId: this.filterSelected.location ? this.filterSelected.location.id : null
        }

        this.loading = true
        this.$http.get('/groups?filter=' + JSON.stringify(filter) + '&page=' + this.page)
            .then(response => {
              this.items = response.data.models
              this.page = response.data.page
              this.total = response.data.total
              this.pageCount = response.data.pageCount
              this.loading = false
            })
            .catch(error => {
              this.loading = false
            })
      },

      loadModules() {
        return this.$http.get('/modules')
      },

      loadExamLevels() {
        return this.$http.get('/exam-levels')
      },

      /**
       * Поиск городов.
       * @param search
       */
      citySearch(search = '') {
        const filter = {name: search}
        this.$http.get('/cities?filter=' + JSON.stringify(filter))
            .then(response => this.filter.cities = response.data)
      },

      /**
       * Поиск помещений.
       * @param search
       */
      locationSearch(search = '') {
        const filter = {name: search}
        this.$http.get('/locations?filter=' + JSON.stringify(filter))
            .then(response => this.filter.locations = response.data.models)
      },

      /**
       * Показать/скрыть фильтры.
       * @param event
       */
      toggleFilter(event) {
        event.target.parentNode.classList.toggle('panel-hide')
      },

      /**
       * Перенаправляет на страницу группы.
       * @param id
       */
      onShowGroup(id) {
        this.$router.push({name: 'GroupInfo', params: {id: id}})
      },

      loadQueryParams() {
        this.filterSelected.name = this.$route.query.name
        this.filterSelected.levelId = this.$route.query.levelId
        this.filterSelected.examDate = this.$route.query.examDate
        this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1
      },

      setQueryParams() {
        let query = {
          name: this.filterSelected.name,
          levelId: this.filterSelected.levelId,
          examDate: this.filterSelected.examDate,
          page: this.page
        }

        this.$router.replace({name: 'Groups', query: query})
      }
    }
  }
</script>
