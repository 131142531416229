export default {
    /**
     * Статусы претендентов на эказмене.
     */
    statuses: {
        exam: {
            text: 'На экзамене',
            color: 'rgba(137, 196, 244, 0.5)'
        },
        late: {
            text: 'Опоздал',
            color: 'rgba(254, 241, 96, 0.5)'
        },
        'not-appear': {
            text: 'Не явился',
            color: 'rgba(190, 144, 212, 0.5)'
        },
        complete: {
            text: 'Экзамен завершен',
            color: 'rgba(49, 211, 54, 0.7)'
        },
        pause: {
            text: 'Экзамен приостановлен',
            color: 'rgba(137, 196, 244, 0.5)'
        },
        fail: {
            text: 'Нарушение',
            color: 'rgba(240, 52, 52, 0.5)'
        },
        'tech-fail': {
            text: 'Технический сбой',
            color: 'rgba(240, 52, 52, 0.5)'
        }
    },

    /**
     * Статусы перетендентов, достпные для действий.
     */
    actionStatuses: [
        { id: 'exam', text: 'На экзамене' },
        { id: 'complete', text: 'Экзамен завершён' },
        { id: 'not-appear', text: 'Не явился' },
        { id: 'fail', text: 'Нарушение' },
        { id: 'late', text: 'Опоздал' },
        { id: 'tech-fail', text: 'Технический сбой' }
    ],

    /**
     * Статусы экзаменов
     */
    examStatuses: {
        new: {
            text: 'Ожидание экзамена',
            color: 'rgba(120, 120, 120, 0.5)'
        },
        exam: {
            text: 'На экзамене',
            color: 'rgba(137, 196, 244, 0.5)'
        },
        completed: {
            text: 'Экзамен завершен',
            color: 'rgba(49, 211, 54, 0.7)'
        },
        complete: {
            text: 'Экзамен завершен',
            color: 'rgba(49, 211, 54, 0.7)'
        },
    },

    /**
     * Время продления/приостановки экзамена.
     */
    times: [
        {
            time: 5,
            text: '5 минут'
        },
        {
            time: 15,
            text: '15 минут'
        },
        {
            time: 30,
            text: '30 минут'
        },
        {
            time: 60,
            text: '1 час'
        }
    ],
}