<template>
  <section class="group-info" v-if="group">
    <h1>Экзаменационная группа {{group.name}}</h1>

    <section class="panel mt-4" >
      <GroupHeader :group="group"></GroupHeader>
      <p>
        <button class="btn btn-bordered" @click="printProtocolAppear">Распечатать протокол явки</button>
        &nbsp;
        <button class="btn btn-bordered" @click="printResultHash">Распечатать контрольные суммы</button>
      </p>
    </section>

    <MembersGrid :showFileColumn="group.exam.levelId > 1"></MembersGrid>

    <p class="text-right">
      <button class="btn btn-bordered" @click="printApplicantsData">Распечатать учётные данные</button>
      &nbsp;
      <button class="btn btn-danger" :disabled="group.status === 'exam'" @click="createApplicantsData">Выдать учётные данные</button>
      &nbsp;
      <button class="btn btn-danger" :disabled="group.status !== 'exam'" @click="redirectToProtocol">Протокол экзамена</button>
    </p>

    <div class="alert-block">
      <div class="alert alert-danger" v-show="alert.length > 0">{{alert}}</div>
    </div>
  </section>
</template>

<script>
  import MembersGrid from "@/components/supervisor/MembersGrid"
  import GroupHeader from "@/components/supervisor/GroupHeader";

  export default {
    name: "GroupInfo",
    components: {GroupHeader, MembersGrid},
    data() {
      return {
        group: null,
        members: [],
        alert: ''
      }
    },
    created() {
      const id = this.$route.params.id
      this.$http.get('/groups/' + id)
        .then(response => this.group = response.data)
    },
    methods: {
      /**
       * Печать контрольных сумм.
       */
      printResultHash(event) {
        const id = this.$route.params.id

        event.target.setAttribute('disabled', true)
        this.$http.post('/groups/' + id + '/result-hash')
            .then(response => {
              event.target.removeAttribute('disabled')
              window.open(response.data.url, '_blank')
            })
            .catch(error => {
              event.target.removeAttribute('disabled')
              this.alert = 'Возникли ошибки.'
              setTimeout(() => this.alert = '', 3000)
            })
      },

      /**
       * Печать протокола явки.
       */
      printProtocolAppear(event) {
        const id = this.$route.params.id

        event.target.setAttribute('disabled', true)
        this.$http.post('/groups/' + id + '/print-presence')
            .then(response => {
              event.target.removeAttribute('disabled')
              window.open(response.data.url, '_blank')
            })
            .catch(error => {
              event.target.removeAttribute('disabled')
              this.alert = 'Возникли ошибки.'
              setTimeout(() => this.alert = '', 3000)
            })
      },

      /**
       * Ведомость фактического участия.
       */
      printFactApplicants(event) {
        const id = this.$route.params.id

        event.target.setAttribute('disabled', true)
        this.$http.post('/groups/' + id + '/print-presence')
            .then(response => {
              event.target.removeAttribute('disabled')
              window.open(response.data.url, '_blank')
            })
            .catch(error => {
              event.target.removeAttribute('disabled')
              this.alert = 'Возникли ошибки.'
              setTimeout(() => this.alert = '', 3000)
            })
      },

      /**
       * Печать учётных данных.
       */
      printApplicantsData(event) {
        const id = this.$route.params.id

        event.target.setAttribute('disabled', true)
        this.$http.post('/groups/' + id + '/print')
            .then(response => {
              event.target.removeAttribute('disabled')
              window.open(response.data.url, '_blank')
            })
            .catch(error => {
              event.target.removeAttribute('disabled')
              this.alert = 'Возникли ошибки.'
              setTimeout(() => this.alert = '', 3000)
            })
      },

      /**
       * Выдача учётных данных.
       */
      createApplicantsData(event) {
        const id = this.$route.params.id

        event.target.setAttribute('disabled', true)
        this.$http.post('/groups/' + id + '/generate-data')
            .then(response => {
              this.group.status = 'exam'
            })
            .catch(error => {
              event.target.removeAttribute('disabled')
              this.alert = 'Возникли ошибки.'
              setTimeout(() => this.alert = '', 3000)
            })
      },

      /**
       * Редирект на протокол экзамена.
       */
      redirectToProtocol() {
        this.$router.push({name: 'ExamProtocol', params: {id: this.group.id}})
      }
    }
  }
</script>
