import io from 'socket.io-client';
import User from "@/components/User";
import Axios from "axios";

const socket = io(process.env.VUE_APP_SOCKET_URL, {
    transports: ['websocket']
})

const request = Axios.create({
    baseURL: process.env.VUE_APP_API_URL + '/api/exam'
})

let token = null;
let autoTimeInterval = null
let timerInterval = null

export default {
    /**
     * Подписчики на приватные события.
     */
    privateObservables: [],

    /**
     * Подписчики на публичные события.
     */
    publicObservables: [],

    /**
     * Подписчики таймера.
     */
    timerObservable: [],

    /**
     * Подключился к сокету.
     */
    joinRoom: false,

    /**
     * Запускает сервис.
     */
    start() {
        User.initData()
        token = User.accessToken

        // стартует сокет
        this.startSocket()

        // получаем билет
        socket.emit('get_ticket', {token})

        // подписки
        socket.on('private_channel', (data) => {
            this.privateObservables.forEach((object) => {
                if (object.event === data.type) {
                    object.callback(data)
                }
            })
        })
        socket.on('public_channel', (data) => {
            this.publicObservables.forEach((object) => {
                if (object.event === data.type) {
                    object.callback(data)
                }
            })
        })

        // стартуем таймер
        this.startTimer()
    },

    /**
     * Подклюаемся по сокету.
     */
    startSocket() {
        socket.emit('join', { room: token })

        // мгеновенно обновляем время
        setTimeout(() => {
            request.get('/answer/timer', {headers: {Authorization: 'Bearer ' + User.accessToken}})
                .then((response) => {
                    this.timerObservable.forEach((callback) => {
                        callback(response.data)
                    })
                })
        }, 2000)
    },

    /**
     * Запускает сервис для супервизора.
     * @param groupId
     */
    startSupervisor(groupId) {
        User.initData()
        token = User.accessToken

        if (!this.joinRoom) {
            socket.emit('join', { room: token })
            this.joinRoom = true
        }

        // получаем претендентов
        socket.emit('get_members', {token: token, groupId: groupId})

        // подписки
        socket.on('private_channel', (data) => {
            this.privateObservables.forEach((object) => {
                if (object.event === data.type) {
                    object.callback(data)
                }
            })
        })
        socket.on('public_channel', (data) => {
            this.publicObservables.forEach((object) => {
                if (object.event === data.type) {
                    object.callback(data)
                }
            })
        })

        // поставли setTimeout чтобы не показывалось что время = 0
        setTimeout(() => {socket.emit('current_time', {token})}, 1000)

        setInterval(() => {
            socket.emit('current_time', {token})
        }, 20000)
    },

    /**
     * Подписывает слушателя на событие.
     * @param type
     * @param socketEvent
     * @param callback
     */
    subscribe(type = 'private', socketEvent, callback) {
        if (type === 'private') {
            this.privateObservables.push({'event': socketEvent, callback: callback})
        } else {
            this.publicObservables.push({'event': socketEvent, callback: callback})
        }
    },

    /**
     * Отправляет уведомление по сокету.
     *
     * @param socketEvent
     * @param data
     */
    send(socketEvent, data) {
        if (data === undefined) {
            data = {}
        }

        data.token = token
        socket.emit(socketEvent, data)
    },

    /**
     * Подписыват слушателей для таймера.
     * @param callback
     */
    subscribeTimer(callback) {
        this.timerObservable.push(callback)
    },

    /**
     * Запускает таймер.
     */
    startTimer() {
        timerInterval = setInterval(() => {
            request.get('/answer/timer', {headers: {Authorization: 'Bearer ' + User.accessToken}})
                .then((response) => {
                    this.timerObservable.forEach((callback) => {
                        callback(response.data)
                    })
                })
        }, 60000)
    },

    /**
     * Отключаемся от сокета.
     */
    closeSocket() {
        socket.emit('leave', { room: token })
    },

    /**
     * Останавливает таймер.
     */
    closeTimer() {
        if (timerInterval) {
            clearInterval(timerInterval)
        }
    },

    /**
     * Запускает сервис по автосохранению.
     * @param callback
     */
    startAutoSaveService(callback) {
        autoTimeInterval = setInterval(callback, 600000)
    },

    /**
     * Останавливает сервис по автосохранению.
     */
    closeAutoSaveService() {
        if (autoTimeInterval !== null) {
            clearInterval(autoTimeInterval)
        }
    }
}