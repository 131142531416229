<template>
  <div class="user-info">
    <div>{{ user.getFullName() }}</div>
    <div>регистрационный номер: {{ user.regNumber }}</div>
  </div>
</template>

<script>

import User from '@/components/User'

export default {
  name: "UserInfo",
  data() {
    return {
      user: User
    }
  }
}
</script>
