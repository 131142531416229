<template>
  <div class="login-page">
    <div class="form-group">
      <img src="../assets/logo.svg" />
    </div>

    <div class="panel" style="padding: 55px 35px" v-if="!status">
      <h1>Вход</h1>
      <br>
      <form @submit="signIn">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Логин" v-model="login">
          <div class="error">{{errors.login}}</div>
        </div>
        <div class="form-group">
          <input type="password" class="form-control" placeholder="Пароль" v-model="password">
          <div class="error">{{errors.password}}</div>
        </div>
        <br>
        <div>
          <button type="submit" class="btn btn-lg btn-danger w-100">Войти</button>
        </div>
      </form>
    </div>

    <div class="panel" style="padding: 55px 35px" v-if="status">
      <h4>Экзамен успешно завершён.</h4>
      <p>Пожалуйста закройте в браузере эту вкладку и откройте новую.</p>
    </div>

    <div class="alert-block">
      <div class="alert alert-danger" v-show="alert.length > 0">{{alert}}</div>
    </div>
  </div>
</template>

<script>

import User from '@/components/User'

export default {
  name: "Login",
  data() {
    return {
      login: '',
      password: '',
      errors: {},
      alert: '',
      status: null
    }
  },
  created() {
    if (User.isAuth()) {
      this.redirectFromRole()
    }

    // показываем страницу заглушку об окончании экзамена
    this.status = this.$route.query.status
    if (this.status === undefined || this.status !== 'finish') {
      this.status = null;
    }
  },
  methods: {
    /**
     * Отправка запроса на авторизацию.
     */
    signIn(e) {
      if (this.validate()) {
        this.$http.post('/user/login', {
          login: this.login.trim(),
          password: this.password.trim()
        })
          .then((response) => {
            User.login(response.data)
            this.redirectFromRole()
          })
          .catch((error) => {
            this.alert = error.response.data[0].message
            setTimeout(() => this.alert = '', 3000)
          })
      }
      e.preventDefault()
    },
     /**
      * Валидация формы.
      * @return {boolean}
      */
    validate() {
      this.errors = {}

      if (this.login.trim().length === 0) {
        this.errors.login = 'Заполните Логин.'
      }

      if (this.password.trim().length === 0) {
        this.errors.password = 'Заполните Пароль.'
      }

      return Object.keys(this.errors).length === 0
    },

    /**
     * Редиректит на страницу в зависимочти от роли.
     */
    redirectFromRole() {
      if (User.role === 'supervisor') {
        this.$router.push({name: 'CreateExam'})
      } else {
        this.$router.push({name: 'Rules'})
      }
    }
   }
}
</script>
