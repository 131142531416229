<template>
  <div class="default-layout" v-show="authUser.isAuth()">
    <header class="header">
      <nav class="navbar-default">
        <div class="container">
          <section class="header-menu">
            <div class="navbar-block block-finish">
              <div class="d-flex cursor-pointer" v-b-modal.modal-finish>
                <svg class="finish-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                  <path d="M13 3h-2v10h2V3zm4.83 2.17l-1.42 1.42C17.99 7.86 19 9.81 19 12c0 3.87-3.13 7-7 7s-7-3.13-7-7c0-2.19 1.01-4.14 2.58-5.42L6.17 5.17C4.23 6.82 3 9.26 3 12c0 4.97 4.03 9 9 9s9-4.03 9-9c0-2.74-1.23-5.18-3.17-6.83z"></path>
                </svg>
                <span style="margin-left: 10px">Закончить экзамен</span>
              </div>
            </div>
            <div class="navbar-block text-center">
              <div>До окончания экзамена:</div>
              <div class="time-expired" v-html="timeText"></div>
              <div>
                <span class="pause" title="Пауза">
                  <b-icon icon="pause-fill"></b-icon>
                </span>
                &nbsp;
                <span class="pause" title="Выйти из системы" v-b-modal.modal-exit style="padding: 4px 0 2px;">
                  <svg class="finish-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M13 3h-2v10h2V3zm4.83 2.17l-1.42 1.42C17.99 7.86 19 9.81 19 12c0 3.87-3.13 7-7 7s-7-3.13-7-7c0-2.19 1.01-4.14 2.58-5.42L6.17 5.17C4.23 6.82 3 9.26 3 12c0 4.97 4.03 9 9 9s9-4.03 9-9c0-2.74-1.23-5.18-3.17-6.83z"></path>
                  </svg>
                </span>
              </div>
            </div>
            <div class="navbar-block text-center pt-3">
              <UserInfo></UserInfo>
            </div>
          </section>
        </div>
      </nav>
    </header>
    <main class="container">
      <slot />
    </main>

    <div class="alert-block">
      <div class="alert alert-danger" v-show="alert.length > 0">{{alert}}</div>
    </div>
    <b-modal id="modal-finish" hide-footer scrollable size="lg" centered>
      <div v-if="Object.keys(result).length === 0">
        <h4>Вопросов отвечено {{ 40 - this.$store.getters.getRemain }} из 40.</h4>
        <p>Вы действительно хотите закончить экзамен?</p>
        <div class="d-flex justify-content-between">
          <button class="btn btn-danger" @click="finishExam">Закончить</button>
          <button class="btn btn-default" @click="cancel">Отменить</button>
        </div>
      </div>
      <div v-else>
        <h4 class="form-group">Экзамен закончен!</h4>
        <div class="row form-group">
          <div class="col-sm-7">
            <div class="finish-group">
              <div class="hint">ФИО претиндента</div>
              <div class="value">{{username}}</div>
            </div>
          </div>
          <div class="col-sm-5">
            <div class="finish-group">
              <div class="hint">Вопросов отвечено</div>
              <div class="value">{{result.stat.answered}} из {{result.stat.total}}</div>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-sm-5">
            <div class="finish-group">
              <div class="hint">Уровень</div>
              <div class="value">{{result.level.name}}</div>
            </div>
          </div>
          <div class="col-sm-7">
            <div class="finish-group">
              <div class="hint">Дата экзамена</div>
              <div class="value">{{moment().format('DD MMMM YYYY')}}</div>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-sm-12">
            <div class="finish-group">
              <div class="hint">Модуль</div>
              <div class="value">{{result.modules[0].name}}</div>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-sm-12">
            <div class="finish-group">
              <div class="hint">Оценка</div>
              <div class="value">{{result.mark}}</div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-exit" hide-footer scrollable size="xs" centered>
      <p>Вы действительно хотите выйти из системы?</p>
      <hr>
      <div class="text-right">
        <button class="btn btn-danger" @click="exit">Выйти</button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import UserInfo from "@/components/UserInfo";
import Socket from "@/components/socket";
import User from "@/components/User";

export default {
  components: {UserInfo, User},
  data() {
    return {
      authUser: User,
      username: User.getFullName(),
      timeText: '&nbsp;',
      result: {},
      alert: ''
    }
  },
  created() {
    if (!User.isAuth()) {
      this.$router.push({name: 'Login'})
      return
    }

    Socket.start()
    Socket.subscribeTimer((response) => {
      this.timeText = response.text

      // завершаем экзамен
      if (response.time < 0) {
        this.finishExam()
      }
    })
  },
  methods: {
    /**
     * Закрывает форму.
     */
    cancel() {
      this.$nextTick(() => {
        this.$bvModal.hide('modal-finish')
      })
    },

    /**
     * Завершает экзамен.
     */
    finishExam() {
      this.$http.post('/demo/test-finish')
        .then((response) => {
          Socket.send('member_finish_exam')
          this.result = response.data
          this.authUser.logout()
          this.$bvModal.show('modal-finish')
          setTimeout(() => {
            this.$nextTick(() => {
              this.$bvModal.hide('modal-finish')
              location.reload()
            })
            Socket.closeSocket()
            Socket.closeTimer()
            window.location.href = '/?status=finish'
          }, 20000)
        })
        .catch((error) => {
          this.alert = 'Возникли проблемы с завершением экзамена.'
          setTimeout(() => this.alert = '', 3000)
        })
    },

    /**
     * Выйти из системы.
     */
    exit() {
      this.$bvModal.hide('modal-exit')
      this.authUser.logout()
      location.href = '/'
    }
  }
}
</script>