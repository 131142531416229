<template>
  <div class="simple-test form-group">
    <p>Выберите ОДИН правильный ответ:</p>
    <div class="form-group" v-for="answer in segment.test.answers">
      <b-form-radio name="question" v-model="applicantAnswer" :value="answer.id">
        <span :class="(answer.correct && preview) ? 'text-correct' : ''" v-html="answer.content"></span>
      </b-form-radio>
    </div>
  </div>
</template>

<script>
  export default {
    name: "SimpleTest",
    props: ['segment', 'changeCallback', 'preview'],
    data() {
      return {
        applicantAnswer: null
      }
    },
    watch: {
      applicantAnswer: function (value) {
        this.$store.commit('addAnswer', {
          segmentId: this.segment.id,
          answers: [value]
        })

        // вызываем тригер изменения
        if (this.changeCallback && value !== undefined) {
          this.changeCallback([value])
        }
      }
    }
  }
</script>
