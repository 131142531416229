<template>
  <section class="grid">
    <section class="panel-grid mt-4">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>ФИО</th>
            <th>Модуль</th>
            <th>Регистрационный номер</th>
            <th>Дата регистрации</th>
            <th>Кол-во ответов</th>
            <th v-if="showFileColumn">Файл</th>
            <th>Статус</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" @click="onShowMember(item)" class="cursor-pointer">
            <td><b>{{UserFormat.getFullName(item.applicant.user)}}</b></td>
            <td>{{item.module.name}}</td>
            <td>{{item.applicant.regNumber}}</td>
            <td>{{moment(item.applicationCreatedAt).format('DD MMMM YYYY')}}</td>
            <td>{{item.countAnswers}}</td>
            <td v-if="showFileColumn">
              <span class="badge badge-success" v-if="item.isAttachFile">Прикреплён</span>
              <span class="badge badge-secondary" v-if="!item.isAttachFile">Нет</span>
            </td>
            <td width="200">
              <span class="status-label" :style="'background-color: ' + statuses[item.status].color">
                {{statuses[item.status].text}}
              </span>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="6">
              <div class="row">
                <div class="col-md-6">
                  <Paginate
                      v-model="page"
                      :page-count="pageCount"
                      :page-range="5"
                      :container-class="'pagination'"
                      :page-class="'pagination-item'"
                      :page-link-class="'pagination-item-link'"
                      :prev-text="'<'"
                      :next-text="'>'"
                      :click-handler="loadMembers">
                  </Paginate>
                </div>
                <div class="col-md-6">
                  <img src="../../assets/loader.gif" class="footer-loader" v-show="loading">
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </section>

    <b-modal id="modal-member" centered size="xl" :hide-footer="true">
      <h4>Информация о претенденте</h4>
      <div class="row mt-4">
        <div class="col-md-6">
          <div class="field form-group">
            <label>ФИО</label>
            <div class="field-value">{{modalMember ? UserFormat.getFullName(modalMember.applicant.user) : ''}}</div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="field form-group">
            <label>Регистрационный номер</label>
            <div class="field-value">{{modalMember ? modalMember.applicant.regNumber : ''}}</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="field form-group">
            <label>Место проживания (город)</label>
            <div class="field-value">{{modalMember ? modalMember.applicant.region.name : ''}}</div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="field form-group">
            <label>Дата заявки</label>
            <div class="field-value">{{modalMember ? moment(modalMember.createdAt).format('DD MMMM YYYY') : ''}}</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <div class="field form-group">
            <label>Учётные данные</label>
            <div class="field-value">{{modalMember && modalMember.isGenerateData ? 'Выданы' : ' Не выданы'}}</div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="field form-group">
            <label>Одноразовый логин</label>
            <div class="field-value">{{modalMember ? modalMember.login : ''}}</div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="field form-group">
            <label>Одноразовый пароль</label>
            <div class="field-value">{{modalMember ? modalMember.password : ''}}</div>
          </div>
        </div>
        <div class="col-md-3 text-center">
          <button class="btn btn-bordered" :disabled="modalMember && modalMember.isGenerateData === 1" @click="createMemberData(modalMember)">
            Сгенерировать одноразовые рег. данные
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="field form-group">
            <label>Статус экзамена</label>
            <div>
              <select class="field-value" v-model="modalMemberStatus">
                <option v-for="option in memberStatuses" :value="option.id">
                  {{option.text}}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-md-6">
          <button class="btn btn-bordered" @click="printMemberData">Распечатать рег. данные</button>
        </div>
        <div class="col-md-6 text-right">
          <button class="btn btn-danger" @click="updateStatus">Сохранить</button>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
  import 'vue-select/dist/vue-select.css'
  import userFormatter from '@/formatters/user'
  import VueSelect from 'vue-select'
  import Paginate from 'vuejs-paginate'
  import Enums from '@/components/enums'

  export default {
    name: "MembersGrid",
    components: {VueSelect, Paginate},
    props: ['showFileColumn'],
    data() {
      return {
        loading: true,
        UserFormat: userFormatter,
        items: [],
        page: 1,
        total: 0,
        pageCount: 1,
        statuses: Enums.statuses,
        memberStatuses: Enums.actionStatuses,
        modalMember: null,
        modalMemberStatus: null
      }
    },
    created() {
      this.loadMembers(this.page)
    },
    methods: {
      /**
       * Загружает претендентов группы.
       * @param page
       */
      loadMembers(page = 1) {
        const id = this.$route.params.id
        const params = {
          sort: '-id',
          page: page
        }

        this.loading = true
        this.$http.get('/groups/' + id + '/members?' + Object.keys(params).map(key => key + '=' + params[key]).join('&'))
            .then(response => {
              this.items = response.data.models
              this.page = response.data.page
              this.total = response.data.total
              this.pageCount = response.data.pageCount
              this.loading = false
            })
            .catch(error => {
              this.loading = false
            })
      },

      /**
       * Показывает модальное окно с информацией о претенденте.
       * @param member
       */
      onShowMember(member) {
        this.modalMember = member
        this.modalMemberStatus = member.status
        this.$bvModal.show('modal-member')
      },

      /**
       * Создаёт одноразовые данные для претендента.
       */
      createMemberData() {
        const id = this.$route.params.id
        const params = {
          members: [this.modalMember.id]
        }
        this.$http.post('/groups/' + id + '/generate-data', params)
          .then(response => {
            this.modalMember.isGenerateData = response.data.isGenerateData
            this.modalMember.login = response.data.login
            this.modalMember.password = response.data.password
          })
      },

      /**
       * Изменяет статус претендента.
       */
      updateStatus(event) {
        const params = {
          status: this.modalMemberStatus
        }

        event.target.setAttribute('disabled', true)
        this.$http.put('/group-members/' + this.modalMember.id, params)
          .then(response => {
            event.target.removeAttribute('disabled')
            this.modalMember.status = this.modalMemberStatus
            this.$bvModal.hide('modal-member')
          })
          .catch(error => {
            event.target.removeAttribute('disabled')
          })
      },

      /**
       * Печатает одноразвые данные претендента.
       */
      printMemberData() {
        const id = this.$route.params.id
        const params = {
          members: [this.modalMember.id]
        }
        this.$http.post('/groups/' + id + '/print', params)
          .then(response => {
            window.open(response.data.url, '_blank')
          })
      }
    }
  }
</script>
