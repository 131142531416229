<template>
  <section class="groups">
    <h1 class="mb-3">Список групп</h1>
    <GroupsGrid></GroupsGrid>
  </section>
</template>

<script>
  import GroupsGrid from "@/components/supervisor/GroupsGrid";
  export default {
    name: "Groups",
    components: {GroupsGrid}
  }
</script>
