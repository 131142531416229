<template>
  <div>
    <section v-if="segment && !loading">
      <h5 class="form-group font-weight-bold module-name mt-4">
        {{ segment.test.module.name }}
      </h5>
      <b-tabs content-class="mt-3" class="tabs-segments">
        <b-tab lazy v-bind:title="'' + segment.id">
          <div class="form-group">
            <div class="panel form-group">
              <section class="segment">
                <h5 class="form-group font-weight-bold">
                  {{segment.test.module.name}}
                </h5>
                <div class="form-group text">
                  <strong v-html="segment.test.question.content"></strong>
                </div>
                <div v-if="segment.test.typeId === 1" class="segment-answers">
                  <SimpleTest :segment="segment" :preview="true"></SimpleTest>
                </div>
                <div v-if="segment.test.typeId === 2" class="segment-answers">
                  <MultipleTest :segment="segment" :preview="true"></MultipleTest>
                </div>
                <div v-if="segment.test.typeId === 3" class="segment-answers">
                  <MatchingTest :segment="segment" :preview="true"></MatchingTest>
                </div>
                <div v-if="segment.test.typeId === 4" class="segment-answers">
                  <CompoundTest :segment="segment" :preview="true"></CompoundTest>
                </div>
                <div v-if="segment.test.typeId === 5" class="segment-answers">
                  <SequenceTest :segment="segment" :preview="true"></SequenceTest>
                </div>
                <div v-if="segment.test.typeId === 6" class="segment-answers">
                  <CalculateTest :segment="segment" :preview="true"></CalculateTest>
                </div>
              </section>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </section>

    <section class="text-center mt-4" v-if="loading">
      Загружаем данные ...
    </section>

    <section class="text-center text-danger mt-4" v-if="error.length > 0">
      {{ error }}
    </section>
  </div>
</template>

<script>

import Axios from "axios";
import SimpleTest from "@/components/tests/SimpleTest";
import CompoundTest from "@/components/tests/CompoundTest";
import MultipleTest from "@/components/tests/MultipleTest";
import MatchingTest from "@/components/tests/MatchingTest";
import SequenceTest from "@/components/tests/SequenceTest";
import CalculateTest from "@/components/tests/CalculateTest";

export default {
  name: "PreviewSegment",
  components: {CalculateTest, SequenceTest, MatchingTest, MultipleTest, CompoundTest, SimpleTest},
  data() {
    return {
      segment: null,
      loading: true,
      error: ''
    }
  },
  created() {
    this.loading = true
    this.error = ''
    Axios.get(`/api/v1/segments/${this.$route.params.id}?token=${this.$route.params.token}`, {
      baseURL: process.env.VUE_APP_API_URL
    })
      .then((response) => {
        this.segment = response.data
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        this.error = 'Не удалось загрузить данные, попробуйте позднее.'
      })
  }
}
</script>