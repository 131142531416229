<template>
  <section class="exam-protocol" v-if="group">
    <h1>Протокол экзамена группы {{group.name}}</h1>

    <section class="panel mt-4">
      <GroupHeader :group="group"></GroupHeader>
      <div class="mt-2">
        <button class="btn btn-bordered" v-b-modal.modal-pause-all>Приостановить экзамен</button>
        &nbsp;
        <button class="btn btn-bordered" v-b-modal.modal-add-time-all>Продлить экзамен</button>
      </div>
    </section>

    <section class="panel-grid mt-4">
      <div class="loader text-center" v-show="loading">
        <img src="../../assets/loader.gif" class="file-loader" alt="loader">
      </div>
      <table class="table table-hover">
        <thead>
        <tr>
          <th>ФИО</th>
          <th>Модуль</th>
          <th></th>
          <th>Билет</th>
          <th>Кол-во ответов</th>
          <th>Статус</th>
        </tr>
        </thead>
        <tbody>
        <template v-for="member in members">
          <tr class="cursor-pointer" @click="showNotifications(member, $event)">
            <td><b>{{UserFormat.getFullName(member.applicant.user)}}</b></td>
            <td>{{member.module.name}}</td>
            <td>
                <span v-show="member.notify">
                  <svg class="icon-red" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
                  </svg>
                </span>
            </td>
            <td>Билет {{member.ticket ? member.ticket.number : ''}}</td>
            <td>{{member.countAnswers}}</td>
            <td width="185">
                <span class="status-label" :style="'background-color: ' + statuses[member.status].color">
                  {{statuses[member.status].text}}
                </span>
            </td>
          </tr>
          <tr class="notifications d-none">
            <td colspan="5">
              <div v-for="notification in member.notifications">
                <div class="item">
                  <time>{{moment(notification.createdAt).format('hh:mm')}}</time>
                  {{notification.text}}
                </div>
              </div>
              <hr>
              <div class="mt-3">
                <button class="btn btn-bordered" @click="showAddTime(member)">Продлить время</button>
                &nbsp;
                <button class="btn btn-bordered" @click="showPause(member)">Пауза</button>
                &nbsp;
                <button class="btn btn-bordered" @click="showFail(member)">Заявить о нарушении</button>
                &nbsp;
                <button class="btn btn-bordered" @click="showReopen(member)">Вернуть на экзамен</button>
              </div>
            </td>
          </tr>
        </template>
        </tbody>
      </table>
    </section>

    <div class="alert-block">
      <div class="alert alert-danger" v-show="alert.length > 0">{{alert}}</div>
    </div>

    <b-modal id="modal-finish-exam" scrollable centered size="lg" :hide-footer="true">
      <h4>Завершить экзамен у группы {{group.name}}?</h4>
      <hr>
      <div class="text-right">
        <button class="btn btn-danger" @click="finishExam">Завершить экзамен</button>
      </div>
    </b-modal>
    <b-modal id="modal-add-time" scrollable centered size="xs" :hide-footer="true">
      <h4>Продлить время претенденту</h4>
      <div class="field">
        <label>На</label>
        <select class="field-value" v-model="modalTime">
          <option :value="time.time" v-for="time in times">{{time.text}}</option>
        </select>
      </div>
      <hr>
      <div class="text-right">
        <button class="btn btn-danger" @click="addTime">Добавить</button>
      </div>
    </b-modal>
    <b-modal id="modal-pause-time" scrollable centered size="xs" :hide-footer="true">
      <h4>Приостановить время претенденту</h4>
      <div class="field">
        <label>На</label>
        <select class="field-value" v-model="modalTime">
          <option :value="time.time" v-for="time in times">{{time.text}}</option>
        </select>
      </div>
      <hr>
      <div class="text-right">
        <button class="btn btn-danger" @click="pause">Приостановить</button>
      </div>
    </b-modal>
    <b-modal id="modal-add-time-all" scrollable centered size="xs" :hide-footer="true">
      <h4>Продлить экзамен</h4>
      <div class="field">
        <label>На</label>
        <select class="field-value" v-model="modalTime">
          <option :value="time.time" v-for="time in times">{{time.text}}</option>
        </select>
      </div>
      <hr>
      <div class="text-right">
        <button class="btn btn-danger" @click="addTimeAll">Добавить</button>
      </div>
    </b-modal>
    <b-modal id="modal-pause-all" scrollable centered size="xs" :hide-footer="true">
      <h4>Приостановить экзамен</h4>
      <div class="field">
        <label>На</label>
        <select class="field-value" v-model="modalTime">
          <option :value="time.time" v-for="time in times">{{time.text}}</option>
        </select>
      </div>
      <hr>
      <div class="text-right">
        <button class="btn btn-danger" @click="pauseAll">Приостановить</button>
      </div>
    </b-modal>
    <b-modal id="modal-fail" scrollable centered size="xs" :hide-footer="true">
      <h4>Заявить о нарушении</h4>
      <h6>Претендент: {{modalMember ? UserFormat.getFullName(modalMember.applicant.user) : ''}}</h6>
      <div class="field mt-3">
        <label>Причина</label>
        <div class="field-value">
          <textarea rows="4" v-model="modalReason"></textarea>
        </div>
      </div>
      <hr>
      <div class="text-right">
        <button class="btn btn-danger" @click="fail">Заявить</button>
      </div>
    </b-modal>
    <b-modal id="modal-reopen" scrollable centered size="xs" :hide-footer="true">
      <h4>Вернуть на экзамен</h4>
      <h6>Претендент: {{modalMember ? UserFormat.getFullName(modalMember.applicant.user) : ''}}</h6>

      <div v-if="!responseReopenData">
        <div class="field">
          <label>Добавить минут</label>
          <select class="field-value" v-model="modalTime">
            <option :value="time.time" v-for="time in times">{{time.text}}</option>
          </select>
        </div>
        <hr>
        <div class="text-right">
          <button class="btn btn-danger" @click="reopen">Вернуть</button>
        </div>
      </div>
      <div class="row" v-if="responseReopenData">
        <div class="col-md-6">
          <div class="field form-group">
            <label>Одноразовый логин</label>
            <div class="field-value">{{responseReopenData.login}}</div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="field form-group">
            <label>Одноразовый пароль</label>
            <div class="field-value">{{responseReopenData.password}}</div>
          </div>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
import Socket from '@/components/socket'
import UserFormat from '@/formatters/user'
import Enums from '@/components/enums'
import GroupHeader from "@/components/supervisor/GroupHeader";

export default {
  name: "ExamProtocol",
  components: {GroupHeader},
  data() {
    return {
      loading: false,
      UserFormat: UserFormat,
      groupId: null,
      group: null,
      members: [],
      modalMember: null,
      modalTime: 5,
      modalReason: '',
      statuses: Enums.statuses,
      times: Enums.times,
      alert: '',
      responseReopenData: null
    }
  },
  created() {
    this.groupId = this.$route.params.id
    this.loadGroup()

    Socket.startSupervisor(this.groupId)
    this.loading = true

    Socket.subscribe('private', 'members', response => {
      this.members = response.data
      for (let i = 0; i < this.members.length; i++) {
        this.members[i].notify = false
        this.members[i].notifications = []
      }
      this.loading = false
    })

    Socket.subscribe('private', 'notifications', response => {
      const index = this.members.map(item => item.id).indexOf(response.data.memberId)
      this.members[index].notifications = response.data.notifications
      this.$forceUpdate()
    })

    Socket.subscribe('public', 'notifications', response => {
      const index = this.members.map(item => item.id).indexOf(response.data.memberId)
      this.members[index].notifications = response.data.notifications
      this.members[index].status = response.data.status
      this.$forceUpdate()
    })

    Socket.subscribe('public', 'member_finish_exam', (response) => {
      const index = this.members.map(item => item.id).indexOf(response.data.memberId)
      this.members[index].notify = true
      this.members[index].status = 'complete'
      this.members[index].countAnswers = response.data.countAnswers
      this.$forceUpdate()
    })

    Socket.subscribe('public', 'finish', (response) => {
      if (response.data.groupId === this.group.id) {
        this.$bvModal.hide('modal-finish-exam')
        this.alert = 'Экзамен завершён'
        setTimeout(() => this.alert = '', 3000)
      }
    })
  },
  methods: {
    /**
     * Загружает группу.
     */
    loadGroup() {
      this.$http.get('/groups/' + this.groupId)
          .then(response => this.group = response.data)
    },

    /**
     * Показать уведомления.
     * @param member
     * @param event
     */
    showNotifications(member, event) {
      if (member.notifications.length === 0) {
        Socket.send('get_notifications', {
          memberId: member.id,
          groupId: this.groupId
        })
      }
      event.target.closest('tr').classList.toggle('selected')
      event.target.closest('tr').nextSibling.classList.toggle('d-none')

      const index = this.members.map(item => item.id).indexOf(member.id)
      this.members[index].notify = false
      this.$forceUpdate()
    },

    /**
     * Показать окно для продления времени.
     */
    showAddTime(member) {
      this.modalMember = member;
      this.$bvModal.show('modal-add-time')
    },

    /**
     * Продлить время.
     */
    addTime() {
      this.$bvModal.hide('modal-add-time')
      Socket.send('extend_time', {
        id: this.modalMember.id,
        time: this.modalTime
      })
    },

    /**
     * Показать окно для приостановки экзамена.
     */
    showPause(member) {
      this.modalMember = member;
      this.$bvModal.show('modal-pause-time')
    },

    /**
     * Пауза.
     */
    pause() {
      this.$bvModal.hide('modal-pause-time')
      Socket.send('pause', {
        id: this.modalMember.id,
        time: this.modalTime
      })
    },

    /**
     * Показывает окно для заявлении о нарушении.
     * @param member
     */
    showFail(member) {
      this.modalMember = member;
      this.$bvModal.show('modal-fail')
    },

    /**
     * Заявить о нарушении.
     */
    fail() {
      if (this.modalReason.length > 0) {
        Socket.send('fail', {
          id: this.modalMember.id,
          reason: this.modalReason
        })
        this.$bvModal.hide('modal-fail')
      }
    },

    /**
     * Показывает окно для возврата претендента на экзамен.
     * @param member
     */
    showReopen(member) {
      this.modalMember = member;
      this.responseReopenData = null
      this.$bvModal.show('modal-reopen')
    },

    /**
     * Возвращает претендента на экзамен.
     */
    reopen() {
      this.$http.post('/members/reopen', {id: this.modalMember.id, time: this.modalTime})
          .then((response) => {
            this.responseReopenData = response.data
          })
    },

    /**
     * Показать окно для приостановки экзамена.
     */
    pauseAll() {
      this.$bvModal.hide('modal-pause-all')
      Socket.send('pause', {
        groupId: this.group.id,
        time: this.modalTime
      })
    },

    /**
     * Завершает экзамен у всей группы.
     */
    finishExam() {
      Socket.send('finish_exam', {
        groupId: this.group.id
      })
    },

    /**
     * Добавить время всей группе.
     */
    addTimeAll() {
      this.$bvModal.hide('modal-add-time-all')
      Socket.send('extend_time', {
        groupId: this.group.id,
        time: this.modalTime
      })
    }
  }
}
</script>
