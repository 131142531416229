import Vue from 'vue'
import VueRouter from 'vue-router'
import Exam from '../views/applicant/Exam.vue'
import Rules from '../views/applicant/Rules'
import Login from '../views/Login'
import Groups from "@/views/supervisor/Groups"
import GroupInfo from "@/views/supervisor/GroupInfo"
import ExamProtocol from "@/views/supervisor/ExamProtocol"
import CreateExam from "@/views/supervisor/CreateExam";
import PreviewSegment from "../views/PreviewSegment"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      layout: 'login-layout'
    }
  },
  {
    path: '/exam',
    name: 'Exam',
    component: Exam
  },
  {
    path: '/rules',
    name: 'Rules',
    component: Rules,
    meta: {
      layout: 'rules-layout'
    }
  },
  {
    path: '/groups',
    name: 'Groups',
    component: Groups,
    meta: {
      layout: 'supervisor-layout'
    }
  },
  {
    path: '/groups/:id',
    name: 'GroupInfo',
    component: GroupInfo,
    meta: {
      layout: 'supervisor-layout'
    }
  },
  {
    path: '/exam-protocol/:id',
    name: 'ExamProtocol',
    component: ExamProtocol,
    meta: {
      layout: 'supervisor-layout'
    }
  },
  {
    path: '/create-exam',
    name: 'CreateExam',
    component: CreateExam,
    meta: {
      layout: 'supervisor-layout'
    }
  },
  {
    path: '/preview/:id/:token',
    name: 'PreviewSegment',
    component: PreviewSegment,
    meta: {
      layout: 'preview-layout'
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
