<template>
  <div class="rules">
    <h1 class="form-group">Правила проведения экзамена</h1>
    <div class="panel form-group">
      <div class="scrolled-content">
        <div class="content">
          <p>Квалификационный экзамен на получение квалификационного аттестата аудитора (далее - экзамен) проводится Единой аттестационной комиссией в соответствии с Порядком проведения квалификационного экзамена лица, претендующего на получение квалификационного аттестата аудитора, утвержденным Решением Правления СРО ААС от 16 декабря 2022 года.</p>
          <p>Экзамен сдается претендентом самостоятельно на русском языке без помощи переводчика и каких-либо иных лиц.</p>
          <p>Претендент обязан явиться на экзамен с паспортом гражданина Российской Федерации или иным документом, удостоверяющим личность, в ином случае претендент к сдаче экзамена не допускается и считается не явившимся на экзамен.</p>
          <p>При сдаче каждого модуля первого этапа экзамена претендент получает индивидуальный набор тестов, сформированный из всего перечня тестовых вопросов, предлагаемых претендентам на экзамене (далее - индивидуальный набор тестов), автоматически в режиме реального времени путем произвольной выборки с учетом равного уровня сложности для всех претендентов. Индивидуальный набор тестов состоит из 40 вопросов.</p>
          <p>Для получения доступа к индивидуальному набору тестов претенденту необходимо ввести в программном комплексе Комиссии выданные представителем Комиссии логин и пароль. При входе претендента в программный комплекс начинается отсчет времени, предоставляемого для ответов на все вопросы индивидуального набора тестов.</p>
          <p>Претендент получает доступ к каждому следующему вопросу вне зависимости от того, был ли им дан ответ на предыдущий вопрос. Последовательность ответов на вопросы определяется претендентом самостоятельно.</p>
          <p>В течение времени, отведенного для ответов на все вопросы индивидуального набора тестов, допускается возврат претендента к вопросам, ответы на которые им не были даны. Изменение ответов не допускается.</p>
          <p>Каждому претенденту предоставляется для ответов на все вопросы индивидуального набора тестов не более 2 часов. Доступ претендента к индивидуальному набору тестов прекращается автоматически после ответа на все вопросы или по истечении отведенного на ответы времени. </p>
          <p>При проведении экзамена претендент может иметь при себе питьевую воду, лекарства и иные средства, обеспечивающие необходимые рабочие условия для прохождения экзамена (очки, слуховой аппарат и т.п.). Иные личные вещи претендента, в том числе мобильные средства связи, перед началом сдачи экзамена должны быть размещены в специально отведенном месте, определенном представителем Комиссии. При этом мобильные средства связи претендента должны быть выключены.</p>
          <p>При проведении первого этапа экзамена претенденту запрещается:</p>
          <p>а) пользоваться законодательными и иными нормативными правовыми актами Российской Федерации, справочными и иными материалами, а также средствами мобильной и иной связи, компьютерной и иной техники, кроме предоставленных Комиссией;</p>
          <p>б) вести переговоры с другими претендентами;</p>
          <p>в) вести какие-либо записи на бумажном или ином носителе информации, пользоваться калькулятором;</p>
          <p>г) покидать помещение, в котором проводится экзамен, во время экзамена (за исключением случаев, когда решение принимается представителем Комиссии).</p>
          <p>Претендент, нарушивший указанные требования, удаляется из пункта приема экзамена и считается не сдавшим экзамен.</p>
          <p>Претендент обязан выполнять указания представителя Комиссии, обеспечивающие соблюдение правил проведения экзамена. Представитель Комиссии имеет право удалить из помещения претендента, не выполнившего его указание, связанное с проведением экзамена. В таком случае претендент считается не сдавшим экзамен.</p>
          <p>С целью осуществления контроля за соблюдением порядка проведения экзамена Комиссия осуществляет видеосъемку экзамена. Комиссия имеет право принять решение об аннулировании результатов экзамена на основании видеозаписи, подтверждающей нарушение претендентом установленных правил проведения экзамена.</p>
          <p>Комиссия обеспечивает осуществление необходимых организационных и технических мер для защиты установленных Комиссией экзаменационных тестовых вопросов от неправомерного или случайного доступа к нему, уничтожения, изменения, блокирования, копирования, распространения вопросов, а также от иных неправомерных действий. Экзаменационные тестовые вопросы являются собственностью Комиссии. Категорически запрещается полностью или частично воспроизводить и (или) распространять экзаменационные тестовые вопросы Комиссии.</p>
          <p>В случае если при проведении экзамена произошел технический сбой в работе компьютерной техники (в частности, поломка компьютера, сбой программ для ЭВМ, отключение снабжения электрической энергией) или возникли иные обстоятельства, препятствующие претендентам продолжить экзамен (в частности, задымление, пожар), то Комиссия обеспечивает устранение технической неисправности (иных обстоятельств, препятствующих претендентам продолжить экзамен) и предоставляет претенденту возможность продолжить прохождение экзамена. При отсутствии возможности продолжения претендентом экзамена Комиссия устанавливает для него другую дату и время прохождения им экзамена (с учетом вопросов, на которые претендент дал ответы до момента технического сбоя компьютерной техники или возникновения иных обстоятельств, препятствующих претендентам продолжить экзамен).</p>
          <p>За каждый правильный ответ на вопрос в форме теста претендент получает 2 балла, за неправильный ответ или отсутствие ответа - 0 баллов. Претендент, получивший за ответы на все вопросы индивидуального набора тестов по одному модулю первого этапа экзамена 60 и более процентов от наибольшего количества баллов (не менее 48 баллов), считается сдавшим соответствующий модуль первого этапа экзамена. </p>
          <p>Результаты сдачи претендентами соответствующего модуля первого этапа экзамена объявляются Комиссией не позднее 5 рабочих дней с даты проведения экзамена путем уведомления каждого претендента. Датой объявления результатов первого этапа экзамена считается день направления претенденту уведомления о результатах.</p>
          <p>Претендент имеет право подать апелляцию о нарушении положений, установленных в главе II Порядка. Апелляция подается в Комиссию не позднее 22 рабочих дней с даты объявления результатов экзамена. Комиссия рассматривает апелляцию и принимает решение по ней в течение не более 22 рабочих дней с даты получения апелляции Комиссией.</p>
          <p>Апелляция на пересмотр результатов экзамена Порядком проведения экзамена не предусмотрена. Ответы претендентов, хранящиеся на цифровых и (или) бумажных носителях, являются собственностью Комиссии и претендентам не предоставляются</p>
        </div>
      </div>
    </div>
    <div class="mb-1">
      <label>
        <b-checkbox v-model="check">
          я согласен(-на) с правилами проведения экзамена
        </b-checkbox>
      </label>
    </div>
    <div class="form-group">
      <button class="btn btn-danger text-uppercase" v-bind:disabled="!check" @click="startExam">Начать экзамен</button>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Rules',
    data() {
      return {
        check: false
      }
    },
    methods: {
      startExam() {
        if (this.check) {
          this.$router.push({name: 'Exam'})
        }
      }
    }
  }
</script>